import toPIPs from '@/mixins/toPIPs'
import instrumentCheck from '@/mixins/instrumentCheck'

export default async function processTestingTrades({ rawTrades, instrument }) {
  let index = 0 // for plotting individual trades
  const trades = []

  if (rawTrades) {
    rawTrades.forEach(x => {
      const parsedTP = parseFloat(x.tp)
      const parsedSL = parseFloat(x.sl)
      const parsedAct = parseFloat(x.activation) * 100
      const pipsTP = toPIPs(instrument, parsedTP)
      const pipsSL = toPIPs(instrument, parsedSL)
      const tpPrice = Number(instrumentCheck(instrument, x.entry_price, parsedTP))
      const slPrice = Number(instrumentCheck(instrument, x.entry_price, parsedSL))
      const entryPrice = Number(instrumentCheck(instrument, x.entry_price, 0))
      const exitPrice = instrumentCheck(instrument, x.exit_price, 0)

      trades.push({
        id: x.id,
        activation: parsedAct,
        action: x.decision,
        entryTime: x.entry_time,
        entryPrice,
        exitTime: x.exit_time,
        exitPrice,
        metricsId: x.metrics_id,
        instrument,
        index,
        result: {
          signal: x.return,
        },
        sl: slPrice,
        tp: tpPrice,
        pipsSL,
        pipsTP,
        reward: (pipsTP / pipsSL),
      })
      index += 1
    })
  }
  return trades
}
