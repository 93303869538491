import {
  ref,
  computed,
} from '@vue/composition-api'
import useApollo from '@/plugins/apolloClient'
import { gql } from '@apollo/client/core'

import useInstruments from '../useInstruments'
import processJournal from './processJournal'
import getJournalMetrics from './getJournalMetrics'
import getJournal from './getJournal'
import journalObject from './journalObject'
import closePosition from './closePosition'
import takePosition from './takePosition'

const journal = ref({
  loading: {
    passive: false,
    blocking: false,
  },
  journalSocketOpen: false,
  requestLimit: 100,
  metrics: [],
  trades: [],
  tradeIds: [],
  account: 10000,
})

const userId = localStorage.getItem('authId')

export default function useJournalTrades() {
  const { sock } = useApollo()
  const frontSchema = process.env.VUE_APP_HASURA_SCHEMA_FRONT
  const { instrumentActive, instrumentFilter } = useInstruments()
  // can remove newInstrument?
  const newInstrument = computed(() => instrumentActive.value.name)
  const journalTrades = computed(() => journal.value.trades)

  async function getFullJournal() {
    const offset = journal.value.trades.length
    const limit = journal.value.requestLimit
    //
    const rawTrades = await getJournal({ offset, limit })
    // if the user has trades process them
    if (rawTrades) {
      const processed = await processJournal({ rawTrades })
      journal.value.tradeIds.push(...processed.tradeIds)
      journal.value.trades.push(...processed.trades)
      // get metrics, uses Hasura SQL function
      if (journal.value.metrics.length === 0) {
        journal.value.metrics = await getJournalMetrics()
      }
    }
    //
  }

  async function openJournalWebsocket() {
    sock.value.request({
      query: gql`
      subscription openJournalWebsocket {
          ${frontSchema}_user_signals(
            limit: 100,
            order_by: {signal: {timestamp: desc}},
            where: {user_id: {_eq: "${userId}"}})
            ${journalObject}
        }
      `,
    }).subscribe({
      next: async pos => {
        const data = pos.data[`${frontSchema}_user_signals`]
        const processed = await processJournal({ rawTrades: data })
        journal.value.trades = processed.trades
        journal.value.tradeIds = processed.tradeIds
        // update journal metrics
        journal.value.metrics = await getJournalMetrics()
      },
      error: err => console.error('liveJournal', err),
    })
  }

  function journalFilter(trades) {
    // filter trades if no result
    const resultedTrades = trades.filter(t => t.result.user !== 0)
    // filter by instrument
    if (instrumentFilter.value) {
      return resultedTrades.filter(t => t.instrument === newInstrument.value)
    } return resultedTrades
  }

  function positionFilter(trades) {
    const tradeIsOpen = trades.filter(t => t.result.user === 0 && t.result.signal === 0)
    if (instrumentFilter.value) {
      return tradeIsOpen.filter(t => t.instrument === newInstrument.value)
    } return tradeIsOpen
  }

  function setJournalWebsocketOpen() {
    journal.value.journalSocketOpen = true
  }

  return {
    journalMetrics: computed(() => journal.value.metrics),
    journalTrades: computed(() => journalFilter(journalTrades.value)),
    journalIds: computed(() => journal.value.tradeIds),
    journalAccount: computed(() => journal.value.account),
    journalWebsocket: {
      open: openJournalWebsocket,
      setOpen: setJournalWebsocketOpen,
      isOpen: journal.value.journalSocketOpen,
    },
    getFullJournal,
    journalPositions: computed(() => positionFilter(journalTrades.value)),
    closePosition,
    takePosition,
  }
}
