export default function toLocalTime(timestamp) {
  // timestamp should be in Miliseconds
  let newTimestamp = timestamp
  if (timestamp < 164595960000) {
    newTimestamp *= 1000
  }
  const localDate = new Date()
  const timeOffset = localDate.getTimezoneOffset() * 60000 // offset
  return newTimestamp - timeOffset
}
