const journalObject = `{
  id
  user_closed
  user_id
  signal_id
  user_entry_price
  user_entry_time
  user_exit_price
  user_exit_time
  user_result
  comments {
    id
    created_at
    body
    is_open
    current_profit
  }
  signal {
    trade {
      activation
      decision
      entry_price
      entry_time
      exit_price
      exit_time
      id
      metrics_id
      return
      revenue
      sl
      tp
    }
    metric {
      avg_activation
      avg_return
      avg_sl
      avg_tp
      id
      total_return
      trades_lost
      trades_won
    }
    strategy {
      method {
        id
        name
      }
      instrument {
        id
        name
      }
      timeframe {
        id
        name
      }
    }
  }
}`

export default journalObject
