import useApollo from '@/plugins/apolloClient'
import { gql } from '@apollo/client/core'
import journalObject from './journalObject'

const userId = localStorage.getItem('authId')

async function getJournal({ offset, limit }) {
  let journal
  const frontSchema = process.env.VUE_APP_HASURA_SCHEMA_FRONT
  const { http } = useApollo()

  await http.value.query({
    query: gql`
        query getJournal {
          ${frontSchema}_user_signals(
            where: {user_id: {_eq: "${userId}"}},
            limit: ${limit},
            offset: ${offset},
            order_by: {created_at: desc},
          )
          ${journalObject}
        }
      `,
  })
    .then(async result => {
      journal = result.data[`${frontSchema}_user_signals`]
    })
    .catch(err => console.log('error getting journal single', err))
  return journal
}

export default getJournal
