import {
  ref,
  computed,
  // readonly,
  // toRefs,
} from '@vue/composition-api'
import instruments from '@/data/instruments.json'
import useApollo from '@/plugins/apolloClient'
import { gql } from '@apollo/client/core'
import useInstruments from '../useInstruments'

const rates = ref({
  requestLimit: 750,
  activeTimeframe: { name: 'M15', id: 1, candleSize: 900000 },
  ratesSocketOpen: false,
  timeframes: [
    { name: 'M1', id: 1, candleSize: 60000 },
    { name: 'M15', id: 2, candleSize: 900000 },
  ],
  M1: JSON.parse(JSON.stringify(instruments.array)),
  M15: JSON.parse(JSON.stringify(instruments.array)),
  isAll: JSON.parse(JSON.stringify(instruments.boolean)),
  loading: {
    passive: false,
    blocking: false,
  },
})

// "completed" causes bugs with live rates as the previous rate is updated after the new one is added
const rateObject = `
{
  close_ask
  close_bid
  high_ask
  high_bid
  instrument
  low_ask
  low_bid
  open_ask
  open_bid
  time
  timeframe
  volume
}
`

const newRate = r => {
  // make timezone dynamic
  const localDate = new Date()
  const timeOffset = localDate.getTimezoneOffset() * 60000 // have to convert the server time to local time before comparing
  const open = (r.open_bid + r.open_ask) / 2
  const close = (r.close_bid + r.close_ask) / 2
  const rateTime = r.time - timeOffset
  return [
    rateTime,
    Number(open.toFixed(5)),
    Number(r.high_ask.toFixed(5)),
    Number(r.low_bid.toFixed(5)),
    Number(close.toFixed(5)),
    r.volume,
  ]
}

export default function useRates() {
  const frontSchema = process.env.VUE_APP_HASURA_SCHEMA_FRONT

  const { instrumentActive } = useInstruments()
  const { http, sock } = useApollo()

  const newInstrument = computed(() => instrumentActive.value.name)
  const activeTimeframe = computed(() => rates.value.activeTimeframe)

  function getRatesWebsocket() {
    // open websocket connection for all instruments, update limit if number of instruments increases
    sock.value.request({
      query: gql`
            subscription liveRates {
              ${frontSchema}_rates(
                limit: 9,
                order_by: {time: desc},
                where: {timeframe: {_eq: "${activeTimeframe.value.name}"}})
              ${rateObject}
            }
          `,
    }).subscribe({
      next: async nextRate => {
        await nextRate.data.mlfx_rates.forEach(r => {
          const currentRates = rates.value[activeTimeframe.value.name][r.instrument]
          const timestamp = 0
          const nRate = newRate(r)
          const rateLength = rates.value[activeTimeframe.value.name][r.instrument].length - 1
          // console.log('rateLength', rateLength)
          const lastRate = currentRates.slice(-1)
          let rateExists = false
          if (rateLength > -1) rateExists = lastRate[0][timestamp] === nRate[timestamp]

          if (!rateExists && rateLength === -1) {
            // push first rate
            rates.value[activeTimeframe.value.name][r.instrument].push(nRate)
            console.log('Rate None pushing first', rateExists, rateLength, nRate, lastRate)
          } else if (!rateExists && rateLength >= 0 && nRate[timestamp] > lastRate[0][timestamp]) {
            // new rate, add to existing rates
            console.log('Rate New pushing', rateExists, rateLength, nRate, lastRate)
            rates.value[activeTimeframe.value.name][r.instrument].push(nRate)
          } else {
            // rate already exists, update it
            rates.value[activeTimeframe.value.name][r.instrument].splice(rateLength, 1, nRate)
          }
        })
      },
      error: err => console.error('liveRates', err),
    })
    // process returned data
  }

  async function getRates({ loadType }) {
    let offset = rates.value[activeTimeframe.value.name][newInstrument.value].length
    if (offset < rates.value.requestLimit - 2) offset = 0
    const ohlcv = []

    if (!rates.value.loading.passive && !rates.value.loading.blocking) {
      rates.value.loading[loadType] = true
      await http.value.query({
        query: gql`
          query getRates {
            ${frontSchema}_rates(
            where: {timeframe: {_eq: "${activeTimeframe.value.name}"},
             _and: {instrument: {_eq: "${newInstrument.value}"}}},
            limit: ${rates.value.requestLimit},
            offset: ${offset},
            order_by: {time: desc},
            )
            ${rateObject}
          }
        `,
      })
        .then(result => {
          const newRates = result.data[`${frontSchema}_rates`]
          if (newRates.length > 0) {
            newRates.forEach(r => {
              const rate = newRate(r)
              ohlcv.push(rate)
            })

            // add rates to local store in one go since reactive

            // check if we already have rates, excluding live rates
            if (rates.value[activeTimeframe.value.name][newInstrument.value].length > rates.value.requestLimit - 2) {
              ohlcv.reverse()
              rates.value[activeTimeframe.value.name][newInstrument.value].unshift(...ohlcv)
            } else {
              // replace all rates to avoid duplicates from live rates
              ohlcv.reverse()
              rates.value[activeTimeframe.value.name][newInstrument.value] = ohlcv
            }
          } else {
            // no more rates in database
            // provide feedback
          }
          rates.value.loading[loadType] = false
        })
        .catch(err => console.error('getRates', err))
    }
  }

  async function doWeHaveRates(instrument, from, to) {
    console.log('tezz1')
    const tempRates = rates.value[activeTimeframe.value.name][instrument]
    let haveRates = false
    console.log('tezz', haveRates, tempRates)
    // Are there any rates for that instrument stored
    if (tempRates.length > 175) {
      haveRates = tempRates.filter(rate => rate[0] > from && rate[0] < to)
      console.log('tezz', haveRates)
      // if trade is new, make sure minimum # of rates returned
      if (haveRates.length < 175 && haveRates.length !== 0) haveRates = tempRates.slice(tempRates.length - 175, tempRates.length)
      else haveRates = false
    }
    return haveRates
  }

  async function getRatesFromTo({ trade }) {
    const entryTime = trade.entryTime.user || trade.entryTime
    const { instrument } = trade
    const { candleSize } = activeTimeframe.value
    const from = entryTime - (candleSize * 175)
    const to = entryTime + (candleSize * 325)

    let haveRates = await doWeHaveRates(instrument, from, to)

    const plotRates = []

    if (!haveRates) {
      // get rates
      if (!rates.value.loading.blocking) {
        rates.value.loading.blocking = true
        await http.value.query({
          query: gql`
          query getRatesFromTo {
            ${frontSchema}_rates(
            order_by: {time: desc},
            where: {
              timeframe: {_eq: "${activeTimeframe.value.name}"},
              _and: {instrument: {_eq: "${instrument}"},
              _and: {time: {_gte: "${from}"},
              _and: {time: {_lte: "${to}"}}}}},
            )
            ${rateObject}
          }
        `,
        })
          .then(result => {
            const newRates = result.data[`${frontSchema}_rates`]
            console.log('newRates', newRates)
            newRates.forEach(r => {
              const rate = newRate(r)
              plotRates.push(rate)
            })
            plotRates.reverse()
            haveRates = plotRates
          })
          .catch(err => console.log(err))
        rates.value.loading.blocking = false
      }
    }
    return haveRates
  }

  function changeRatesTimeframe(timeframe) {
    const newValue = rates.value.timeframes.filter(tf => tf.name === timeframe)
    // eslint-disable-next-line prefer-destructuring
    rates.value.activeTimeframe = newValue[0]
  }

  function getLatestRate({ instrument }) {
    const rateLength = rates.value[activeTimeframe.value.name][instrument].length - 1
    const rate = rates.value[activeTimeframe.value.name][instrument][rateLength]
    return rate
  }

  function setRatesWebsocketOpen() {
    rates.value.ratesSocketOpen = true
  }

  return {
    ratesLive: computed(() => rates.value[activeTimeframe.value.name][newInstrument.value]),
    rateLatest: instrument => {
      const rateLength = rates.value[activeTimeframe.value.name][instrument].length - 1
      const ratePrice = rates.value[activeTimeframe.value.name][instrument][rateLength][4]
      if (ratePrice < 1) return ratePrice.toFixed(5)
      return ratePrice.toPrecision(6)
    },
    ratesLoading: computed(() => rates.value.loading),
    ratesTimeframe: computed(() => rates.value.activeTimeframe),
    getRates,
    getRatesFromTo,
    ratesWebsocket: {
      open: getRatesWebsocket,
      isOpen: rates.value.ratesSocketOpen,
      setOpen: setRatesWebsocketOpen,
    },
    getLatestRate,
    changeRatesTimeframe,
  }
}
