import toPIPs from '@/mixins/toPIPs'
import instrumentCheck from '@/mixins/instrumentCheck'
import toLocalTime from '@/mixins/toLocalTime'

async function processJournal({ rawTrades }) {
  const trades = []
  const tradeIds = []
  if (rawTrades) {
    rawTrades.forEach(x => {
      const instrument = x.signal.strategy.instrument.name
      const parsedTP = parseFloat(x.signal.trade.tp)
      const parsedSL = parseFloat(x.signal.trade.sl)

      const parsedAct = parseFloat(x.signal.trade.activation) * 100
      const parsedAvgAct = parseFloat(x.signal.metric.avg_activation) * 100
      const avgReturn = parseFloat(x.signal.metric.avg_return)
      const totalReturn = parseFloat(x.signal.metric.total_return)
      const signalPipsTP = toPIPs(instrument, parsedTP)
      const signalPipsSL = toPIPs(instrument, parsedSL)
      const tpPrice = instrumentCheck(instrument, x.signal.trade.entry_price, parsedTP)
      const slPrice = instrumentCheck(instrument, x.signal.trade.entry_price, parsedSL)
      const userPipsSL = toPIPs(instrument, Math.abs(slPrice - x.user_entry_price))
      const userPipsTP = toPIPs(instrument, Math.abs(tpPrice - x.user_entry_price))
      const entryPrice = instrumentCheck(instrument, x.signal.trade.entry_price, 0)
      const userEntryPrice = instrumentCheck(instrument, x.user_entry_price, 0)
      const totalTrades = x.signal.metric.trades_won + x.signal.metric.trades_lost
      const accuracy = Math.round((x.signal.metric.trades_won / totalTrades) * 1000) / 10
      // for displaying new signals on trading page
      const strategyType = x.signal.strategy.method.name

      const year = new Date(x.signal.trade.entry_time).getFullYear()
      const month = new Date(x.signal.trade.entry_time).toLocaleString('en-US', { month: 'short' })
      const day = new Date(x.signal.trade.entry_time).getDate()

      // if the user did not exit the trade default to signal exit metrics
      const userExitTime = x.user_exit_time || x.signal.trade.exit_time
      const userExitPrice = x.user_exit_price || x.signal.trade.exit_price
      let userResult
      let isResulted
      if (x.user_result) {
        // if user exited trade, user_result is stored
        userResult = x.user_result
        isResulted = true
      } else if (x.signal.trade.return > 0) {
        // if TP was hit calculate userResult
        userResult = Math.abs(userPipsTP / userPipsSL)
        isResulted = true
      } else if (x.signal.trade.return < 0) {
        // mark as loss
        userResult = x.signal.trade.return
        isResulted = true
      } else userResult = x.signal.trade.return

      // keep an array of open positions to filter signals against
      tradeIds.push(x.signal_id)

      trades.push({
        date: {
          year,
          month,
          day,
        },
        id: x.signal_id,
        userTradeId: x.id,
        strategyType,
        action: String(x.signal.trade.decision),
        instrument,
        isResulted,
        entryPrice: {
          user: userEntryPrice,
          signal: entryPrice,
        },
        exitPrice: {
          user: instrumentCheck(instrument, userExitPrice, 0),
          signal: instrumentCheck(instrument, x.signal.trade.exit_price, 0),
        },
        comments: x.comments,
        entryTime: {
          user: toLocalTime(x.user_entry_time),
          signal: toLocalTime(x.signal.trade.entry_time),
        },
        exitTime: {
          user: toLocalTime(userExitTime),
          signal: toLocalTime(x.signal.trade.exit_time),
        },
        tp: tpPrice,
        sl: slPrice,
        pipsSL: {
          user: userPipsSL,
          signal: signalPipsSL,
        },
        pipsTP: {
          user: userPipsTP,
          signal: signalPipsTP,
        },
        reward: {
          user: x.user_rr,
          signal: signalPipsTP / signalPipsSL,
        },
        wins: x.signal.metric.trades_won,
        losses: x.signal.metric.trades_lost,
        totalTrades,
        accuracy,
        avgReturn,
        totalReturn,
        activation: parsedAct.toFixed(2),
        avgActivation: parsedAvgAct.toFixed(2),
        userClosed: x.user_closed,
        result: {
          user: Number(userResult),
          signal: Number(x.signal.trade.return),
        },
      })
    })
  }

  return {
    trades,
    tradeIds,
  }
}

export default processJournal
