import {
  ref,
  computed,
  watch,
} from '@vue/composition-api'
import instruments from '@/data/instruments.json'
import useStrategies from '../strategies/useStrategies'
import useInstruments from '../useInstruments'
import processTrades from './processTrades'
import processTestingTrades from './processTestingTrades'
import processTradeMetrics from './processTradeMetrics'
import getTestingTrades from './getTestingTrades'
// import getTradesFromTo from './getTradesFromTo'
import getTrades from './getTrades'
import getTradeMetrics from './getTradeMetrics'
import usePagination from '../usePagination'

const trades = ref({
  filter: false,
  loading: {
    passive: false,
  },
  human: JSON.parse(JSON.stringify(instruments.array)),
  hybrid: JSON.parse(JSON.stringify(instruments.array)),
  hermes: JSON.parse(JSON.stringify(instruments.array)),
  metrics_daily: {
    human: JSON.parse(JSON.stringify(instruments.array)),
    hybrid: JSON.parse(JSON.stringify(instruments.array)),
    hermes: JSON.parse(JSON.stringify(instruments.array)),
  },
  metrics_totals: {
    human: JSON.parse(JSON.stringify(instruments.array)),
    hybrid: JSON.parse(JSON.stringify(instruments.array)),
    hermes: JSON.parse(JSON.stringify(instruments.array)),
  },
  testing: JSON.parse(JSON.stringify(instruments.array)),
  tempTrade: [],
})

export default function useTrades() {
  //
  const { instrumentActive } = useInstruments()
  const { strategyActive } = useStrategies()
  const { paginationSize } = usePagination()

  const strategyType = computed(() => strategyActive.value.toLowerCase())
  const instrumentType = computed(() => instrumentActive.value.name)
  const tradeLength = computed(() => trades.value[strategyType.value][instrumentType.value].length)
  const metLength = computed(() => trades.value.metrics_daily[strategyType.value][instrumentType.value].length)

  async function calculateTradeMetrics() {
    let loading = false
    if (!loading) {
      loading = true
      const mets = await getTradeMetrics(instrumentType.value)
      const processedMetrics = await processTradeMetrics({ mets })
      trades.value.metrics_daily[strategyType.value][instrumentType.value] = processedMetrics.daily
      trades.value.metrics_totals[strategyType.value][instrumentType.value] = processedMetrics.totals
      loading = false
    }
  }

  async function getInitialTrades() {
    const limit = paginationSize.value
    const offset = 0
    if (!trades.value.loading.passive && tradeLength.value === 0) {
      trades.value.loading.passive = true
      const rawTrades = await getTrades({
        limit,
        offset,
        strategyType: strategyActive.value.toUpperCase(),
        instrument: instrumentType.value,
      })
      const processedTrades = await processTrades({ rawTrades, length: tradeLength.value })
      trades.value[strategyType.value][instrumentType.value].push(...processedTrades)
      trades.value.loading.passive = false
    }
  }

  async function getPaginationTrades() {
    const limit = paginationSize.value
    const offset = tradeLength.value
    if (!trades.value.loading.passive) {
      trades.value.loading.passive = true
      const rawTrades = await getTrades({
        limit,
        offset,
        strategyType: strategyActive.value.toUpperCase(),
        instrument: instrumentType.value,
      })
      const processedTrades = await processTrades({ rawTrades, length: tradeLength.value })
      trades.value[strategyType.value][instrumentType.value].push(...processedTrades)
      trades.value.loading.passive = false
    }
  }

  async function setTestingTrades() {
    const rawTrades = await getTestingTrades({ instrument: instrumentType.value })
    const processedTrades = await processTestingTrades({ rawTrades, instrument: instrumentType.value })
    trades.value.testing[instrumentType.value] = processedTrades
  }

  watch(() => instrumentType.value, () => {
    if (tradeLength.value === 0) getInitialTrades()
    if (metLength.value === 0) calculateTradeMetrics()
  })

  watch(() => strategyType.value, () => {
    if (tradeLength.value === 0) {
      getInitialTrades()
    }
  })

  return {
    trades: computed(() => trades.value[strategyType.value][instrumentType.value]),
    tradesTesting: computed(() => trades.value.testing[instrumentType.value]),
    tradeMetrics: computed(() => trades.value.metrics_daily[strategyType.value][instrumentType.value]),
    tradeMetricsTotals: computed(() => trades.value.metrics_totals[strategyType.value][instrumentType.value]),
    getInitialTrades,
    getPaginationTrades,
    calculateTradeMetrics,
    setTestingTrades,
  }
}
