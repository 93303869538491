import {
  ref,
  computed,
} from '@vue/composition-api'
import instruments from '@/data/instruments.json'
import useInstruments from './useInstruments'
import useStrategies from './strategies/useStrategies'
import useTabs from './useTabs'

const pagination = ref({
  pageSize: 600,
  analysis: {
    human: JSON.parse(JSON.stringify(instruments.number)),
    hybrid: JSON.parse(JSON.stringify(instruments.number)),
    hermes: JSON.parse(JSON.stringify(instruments.number)),
  },
  // one for each page?
})

export default function usePagination() {
  // const loading = ref(false)
  const { instrumentActive } = useInstruments()
  const { strategyActive } = useStrategies()
  const { pageName } = useTabs()

  const strategyType = computed(() => strategyActive.value.toLowerCase())
  const instrumentType = computed(() => instrumentActive.value.name)

  function updatePagination(pageNumber) {
    pagination.value[pageName.value][strategyType.value][instrumentType.value] = pageNumber
  }

  return {
    paginationCurrent: computed(() => pagination.value[pageName.value][strategyType.value][instrumentType.value]),
    paginationSize: computed(() => pagination.value.pageSize),
    updatePagination,
  }
}
