import toCurrency from '@/mixins/toCurrency'
import useApollo from '@/plugins/apolloClient'
import { gql } from '@apollo/client/core'

const userId = localStorage.getItem('authId')

export default async function getJournalMetrics() {
  let m
  let metrics
  const account = 10000
  const frontSchema = process.env.VUE_APP_HASURA_SCHEMA_FRONT
  const { http } = useApollo()

  await http.value.query({
    query: gql`
      query getJournalMetrics {
        ${frontSchema}_user_metrics(where: {user_id: {_eq: "${userId}"}}) {
          id
          user_id
          total_return
          losses
          wins
          avg_rr
        }
      }
    `,
  })
    .then(result => {
      [m] = result.data[`${frontSchema}_user_metrics`] || null
      if (m !== null) {
        const ROI = toCurrency.format(account + (account * (m.total_return / 100)))
        const totalTrades = m.wins + m.losses
        metrics = [
          { name: 'Win/Loss', value: `${m.wins} / ${m.losses}` },
          { name: 'Accuracy', value: `${((m.wins / totalTrades) * 100 || 0).toFixed(2)}%` },
          { name: 'Avg. R/R', value: `1 / ${m.avg_rr.toFixed(2)}` },
          { name: 'Avg. return', value: `${(m.total_return / totalTrades || 0).toFixed(2)}%` },
          { name: 'Total return', value: `${m.total_return.toFixed(2)}%` },
          { name: 'Account', value: ROI },
        ]
      } else metrics = []
    })
    .catch(err => console.error('getJournalMetrics', err))

  return metrics
}
