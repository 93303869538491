export default async function processTradeMetrics({ mets }) {
  // what metrics?

  const metrics = {
    daily: [],
    totals: {
      return: 0,
      one_trade_per_day: 0,
      return_on_investment: 1000,
    },
  }

  await mets.forEach(m => {
    const avgReturn = m.total_return / (m.wins + m.losses)
    // metrics.totals.return_on_investment += ((avgReturn / 100) * metrics.totals.return_on_investment)
    // metrics.totals.one_trade_per_day += avgReturn
    // metrics.totals.return += m.total_return
    const accuracy = ((m.wins / (m.wins + m.losses)) * 100).toFixed(2)
    const d = new Date(m.date)
    const date = d.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'short',
    })
    metrics.daily.push({
      date,
      rawDate: m.date,
      wins: m.wins,
      losses: m.losses,
      accuracy,
      totalReturn: m.total_return,
      avgReturn,
      avgReward: m.avg_reward,
      totalTrades: m.wins + m.losses,
      noResult: m.no_result,
      buys: m.buys,
      sells: m.sells,
    })
  })

  // return metrics
  return metrics
}
