import useApollo from '@/plugins/apolloClient'
import { gql } from '@apollo/client/core'

const { http } = useApollo()

const frontSchema = process.env.VUE_APP_HASURA_SCHEMA_FRONT
let loading = false

export default async function getTestingTrades({ instrument }) {
  let rawTrades
  // need to add instrument filter when able
  if (!loading) {
    loading = true
    await http.value.query({
      query: gql`
          query getTrades {
          ${frontSchema}_get_testing_trades(
            limit: 1000,
            distinct_on: entry_time,
            order_by: {entry_time: desc },
            args: {test_instrument: "${instrument}"}
            ) {
            metrics_id
            return
            revenue
            sl
            tp
            id
            exit_time
            exit_price
            entry_time
            entry_price
            decision
            activation
          }
        }
        `,
    })
      .then(result => {
        rawTrades = result.data[`${frontSchema}_get_testing_trades`]
      })
      .catch(err => console.error('getTestingTrades', err))
  }
  loading = false
  return rawTrades
}
