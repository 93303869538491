import useApollo from '@/plugins/apolloClient'
import { gql } from '@apollo/client/core'

const { http } = useApollo()

const frontSchema = process.env.VUE_APP_HASURA_SCHEMA_FRONT

export default async function getTradeMetrics(instrument) {
  let metrics
  // can use day/month/hour (or any date_trunc input) https://mode.com/blog/date-trunc-sql-timestamp-function-count-on/
  await http.value.query({
    query: gql`
      query getTradeMetrics {
        ${frontSchema}_calculate_signal_metrics(args: {inst: "${instrument}", timeperiod: "day" }) {
          id
          date
          wins
          losses
          total_return
          avg_reward
          no_result
          buys
          sells
        }
      }
    `,
  })
    .then(result => {
      metrics = result.data[`${frontSchema}_calculate_signal_metrics`]
    })
    .catch(err => console.error('getTradeMetrics', err))

  return metrics
}
