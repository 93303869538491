import useApollo from '@/plugins/apolloClient'
import { gql } from '@apollo/client/core'
import journalObject from './journalObject'
import useRates from '../rates/useRates'

const frontSchema = process.env.VUE_APP_HASURA_SCHEMA_FRONT
const userId = localStorage.getItem('authId')

const { http } = useApollo()

async function takePosition({ trade }) {
  const { getLatestRate } = useRates()
  const rate = await getLatestRate({ instrument: trade.instrument })

  // trade data to send
  const now = Date.now()
  const userEntryTime = now
  const userEntryPrice = rate[4]

  const reward = Math.abs(userEntryPrice - trade.tp)
  const risk = Math.abs(userEntryPrice - trade.sl)

  const userReward = reward / risk

  console.log('dataSent', userEntryPrice, userEntryTime, userReward)

  await http.value.mutate({
    mutation: gql`
          mutation takeTrade {
          insert_${frontSchema}_user_signals_one(
            object: {
              user_id: "${userId}",
              signal_id: "${trade.id}",
              user_rr: ${userReward},
              user_entry_price: ${userEntryPrice},
              user_entry_time: ${userEntryTime}})
            ${journalObject}            
          }
        `,
  })
    .then(() => {
      // provide feedback that trade taken
    })
    .catch(err => console.error('takePosition', err))
}

export default takePosition
