import toPIPs from '@/mixins/toPIPs'
import instrumentCheck from '@/mixins/instrumentCheck'

async function processTrades({ rawTrades, length }) {
  let index = 0 // for plotting individual trades
  const trades = []

  if (length > 0) {
    index = length + 1
  }

  if (rawTrades) {
    rawTrades.forEach(x => {
      if (x.trade.action !== 'HOLD') {
        const instrument = x.strategy.instrument.name
        const parsedTP = parseFloat(x.trade.tp)
        const parsedSL = parseFloat(x.trade.sl)
        const parsedAct = parseFloat(x.trade.activation) * 100
        const parsedAvgAct = parseFloat(x.metric.avg_activation) * 100
        const parsedRet = parseFloat(x.metric.avg_return)
        const pipsTP = toPIPs(instrument, parsedTP)
        const pipsSL = toPIPs(instrument, parsedSL)
        const tpPrice = instrumentCheck(instrument, x.trade.entry_price, parsedTP)
        const slPrice = instrumentCheck(instrument, x.trade.entry_price, parsedSL)
        const entryPrice = instrumentCheck(instrument, x.trade.entry_price, 0)
        const totalTrades = +x.metric.trades_won + +x.metric.trades_lost
        const accuracy = Math.round((x.metric.trades_won / totalTrades) * 1000) / 10

        trades.push({
          activation: parsedAct,
          avgActivation: parsedAvgAct,
          avgReturn: parsedRet,
          action: x.trade.decision,
          id: x.id,
          instrument,
          result: {
            signal: x.trade.return,
          },
          entryPrice,
          entryTime: x.timestamp,
          candleTime: x.trade.entry_time,
          sl: slPrice,
          tp: tpPrice,
          pipsSL,
          pipsTP,
          timeframe: x.strategy.timeframe.name,
          totalReturn: x.metric.total_return,
          tradesLost: x.metric.trades_lost,
          tradesWon: x.metric.trades_won,
          totalTrades,
          strategyType: x.strategy.method.name,
          index,
          reward: (pipsTP / pipsSL),
          indicators: null,
          accuracy,
        })
        index += 1
      }
    })
  }

  return trades
}

export default processTrades
