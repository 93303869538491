import useApollo from '@/plugins/apolloClient'
import { gql } from '@apollo/client/core'
import toPIPs from '@/mixins/toPIPs'
import journalObject from './journalObject'
import useRates from '../rates/useRates'

let loading = false

const frontSchema = process.env.VUE_APP_HASURA_SCHEMA_FRONT

const { http } = useApollo()

async function closePosition({ trade }) {
  const { getLatestRate } = useRates()
  const rate = await getLatestRate({ instrument: trade.instrument })
  let response
  let data

  if (!loading) {
    loading = true
    let movement
    const sl = trade.pipsSL.user
    const current = toPIPs(trade.instrument, Math.abs(trade.entryPrice.user - rate[4]))

    if (trade.action === 'BUY' && rate[4] > trade.entryPrice.user) {
      // calculate movement towards TP
      movement = current / sl
    } else if (trade.action === 'SELL' && rate[4] < trade.entryPrice.user) {
      // calculate movement towards TP
      movement = current / sl
    } else {
      // movement is towards SL
      movement = -current / sl
    }

    data = {
      userTradeId: trade.userTradeId,
      userExitPrice: rate[4],
      userExitTime: Date.now(),
      userResult: movement,
    }

    loading = false
  }

  await http.value.mutate({
    mutation: gql`
        mutation closeTrade {
          update_${frontSchema}_user_signals_by_pk(
          pk_columns: {id: ${data.userTradeId}},
          _set: {
            user_closed: true,
            user_exit_price: ${data.userExitPrice},
            user_exit_time: ${data.userExitTime},
            user_result: ${data.userResult},
          }) 
          ${journalObject}
        }
      `,
  })
    .then(async result => {
      response = Array(result.data[`update_${frontSchema}_user_signals_by_pk`])
    })
    .catch(err => console.error('closePosition', err))

  return response
}

export default closePosition
