import useApollo from '@/plugins/apolloClient'
import { gql } from '@apollo/client/core'
import tradeObject from './tradeObject'

const { http } = useApollo()

const frontSchema = process.env.VUE_APP_HASURA_SCHEMA_FRONT

export default async function getTrades({
  limit,
  offset,
  // strategyType,
  instrument,
}) {
  let rawTrades
  await http.value.query({
    query: gql`
        query getTrades {
        ${frontSchema}_signals(
          limit: ${limit},
          offset: ${offset},
          order_by: {timestamp: desc },
          where: {strategy: {method: {name: {_eq: "HERMES"}},
            _and: {instrument: {name: {_eq: "${instrument}"}}}}}
        )
        ${tradeObject}
      }
      `,
  })
    .then(result => {
      if (result.data[`${frontSchema}_signals`].length > 0) {
        rawTrades = result.data[`${frontSchema}_signals`]
      } else {
        // no more trades in database
        // provide feedback
      }
    })
    .catch(err => console.error('getTrades', err))
  return rawTrades
}
