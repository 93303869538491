export default function instrumentCheck(instrument, entry, tpsl) {
  const output = entry + tpsl
  const threeDecimal = instrument.search(/JPY/) >= 0
    || instrument.search(/HUF/) >= 0
    || instrument.search(/KRW/) >= 0
    || instrument.search(/THB/) >= 0
  if (threeDecimal) {
    return output.toFixed(3)
  }
  return output.toFixed(5)
}
